import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./client/client.component').then((m) => m.ClientComponent),
    canActivate: [AuthGuard],
  },
];
