<mat-toolbar color=primary>
  <span>Identity Cockpit</span>
  <span class="spacer"></span>
  <button mat-icon-button (click)="authService.logout({ logoutParams: { returnTo: document.location.origin } })">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
@if (loadingService.isLoading$ | async) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<router-outlet/>
